import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectData,
  setCampaign,
  setCampaignLoading,
  setClicked,
  setNewsletter,
  setPrevRangeData,
} from "../../../store/dataSlice";
import { motion } from "framer-motion";
import { MAIN_ROUTE_FADE_UP_ANIMATION_VARIANTS } from "../../../utils/TransitionConstants";
import CampaignFilter from "../../../containers/dashboard/CampaignFilter";
import CampaignOverView from "../../dashboard/CampaignOverView";
import Loading from "../../../components/Loading";
import { IDateRange } from "../../../interfaces/common.interface";
import CreatorAPIInstance from "../../../api/creatorAPIInstance";

const CreatorReporting = () => {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { isCampaignLoading } = useSelector(selectData);
  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const loadCampaignData = useCallback(
    (
      dateRange: IDateRange,
      identifier: string,
      selectedCampaigns: string[]
    ) => {
      dispatch(setCampaignLoading(true));
      Promise.all([
        CreatorAPIInstance.get("getCampaign", {
          params: {
            creatorId: identifier,
            ...(dateRange.endDate &&
              dateRange.startDate && {
                from: dateRange.startDate,
                to: dateRange.endDate,
              }),
            ...(selectedCampaigns.length > 0 && {
              campaignIds: selectedCampaigns,
            }),
          },
        }),
        CreatorAPIInstance.get("getNewsletter", {
          params: {
            creatorId: identifier,
            ...(dateRange.endDate &&
              dateRange.startDate && {
                from: dateRange.startDate,
                to: dateRange.endDate,
              }),
            ...(selectedCampaigns.length > 0 && {
              campaignIds: selectedCampaigns,
            }),
          },
        }),
      ])
        .then((res: Array<any>) => {
          dispatch(setClicked(res[0].data.clicked));
          dispatch(setCampaign({ campaign: res[0].data.data }));
          dispatch(setPrevRangeData(res[0].data.prevData));
          dispatch(setNewsletter(res[1].data));
        })
        .finally(() => {
          dispatch(setCampaignLoading(false));
        });
    },
    [dispatch]
  );

  return (
    <div className="text-left relative pt-1.5 h-full flex flex-col">
      <h1 className="font-semibold font-[Inter] text-[18px] md:text-xl -tracking-[.6px]">
        Reporting
      </h1>
      <p className="text-[12px] md:text-sm xsm:mt-1.5 font-normal text-secondry1">
        Here’s a snapshot of your campaigns, all in one place
      </p>
      <p className="!text-[10px] font-normal text-primary flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          className="h-[14px] w-[14px] ms-1"
        >
          <path d="M460-300h40v-220h-40v220Zm20-276.923q10.462 0 17.539-7.077 7.076-7.077 7.076-17.539 0-10.461-7.076-17.538-7.077-7.077-17.539-7.077-10.462 0-17.539 7.077-7.076 7.077-7.076 17.538 0 10.462 7.076 17.539 7.077 7.077 17.539 7.077ZM480.134-120q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806-120 480.134-120ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
        Click data undergoes multiple validation checks, including filtering for illegitimate or bot activity. Results will populate on the dashboard approximately 48 hours after the newsletter placement is completed.
      </p>

      <div className="flex md:hidden w-full border-[1px] focus:ring-0 border-main mt-4 rounded-[10px] p-2 text-sm font-normal bg-white leading-3.5">
        Open Presspool.ai on a large screen to launch and track referral
        campaigns
      </div>

      <motion.div
        className="flex xsm:hidden"
        initial="hidden"
        animate={`${isFirstLoad && isCampaignLoading ? "" : "show"}`}
        variants={MAIN_ROUTE_FADE_UP_ANIMATION_VARIANTS()}
      >
        <div className="flex-1">
          <CampaignFilter loadCampaignData={loadCampaignData} />
          {!isCampaignLoading && <CampaignOverView />}
        </div>
      </motion.div>
      {isCampaignLoading && (
        <div className="flex-auto relative">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default CreatorReporting;
