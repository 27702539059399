import { FC, Fragment, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Loading from "../../components/Loading";
import countryList from "react-select-country-list";
import ErrorMessage from "../../components/ErrorMessage";
import { message } from "antd";
import Select from "react-select";

const customStyles = () => ({
  control: (provided: Record<string, unknown>) => ({
    ...provided,
    fontSize: "14px",
    border: "1px solid #7F8182 !important",
    borderRadius: "8px",
    width: "100%",
    // "&:hover": {
    //   border: "1px solid #ff8b67",
    //   boxShadow: "0px 0px 6px #ff8b67"
    // }
  }),
});

interface typeCountrySelection {
  show: boolean;
  onClose: Function;
  onSubmit: (country: string) => {};
}

const CountrySelection: FC<typeCountrySelection> = ({
  show,
  onClose,
  onSubmit,
}: typeCountrySelection) => {
  const [value, setValue] = useState<any>(null);
  const [error, setError] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (item) => {
    setError("");
    setValue(item);
  };

  const handleConfirmClick = async () => {
    if (value?.value) {
      setError("");
      onSubmit(value?.value);
    } else {
      setError("Select country");
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-black/[.8]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative bg-white rounded-[10px] text-left flex items-center justify-center flex-col shadow-xl border-[1px] border-black px-[36px] pt-[45px] pb-[26px] min-w-[600px] max-w-[600px]`}
              >
                <button
                  onClick={() => onClose(false)}
                  className="absolute right-4 top-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M13.4444 13.4444L20.5556 20.5556M20.5556 13.4444L13.4444 20.5556M17 1C29.8 1 33 4.2 33 17C33 29.8 29.8 33 17 33C4.2 33 1 29.8 1 17C1 4.2 4.2 1 17 1Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <div className="text-center">
                  <h2 className="text-xl font-semibold -tracking-[.72px]">
                    Connect Stripe to Continue
                  </h2>
                </div>
                <div className="text-left">
                  <p className="text-[#7F8182] text-normal text-sm">
                    In order to get paid out from Presspool, you must link your
                    bank via Stripe.{" "}
                  </p>
                  <p className="text-[#7F8182] text-normal text-sm ">
                    Choose your country from the list below, then click “Create
                    Account.”{" "}
                  </p>
                </div>

                <Select
                  className="mt-2 w-full px-4 py-2.5"
                  styles={customStyles()}
                  options={options}
                  value={value}
                  onChange={changeHandler}
                />
                <div className="self-start">
                  {error && <ErrorMessage message={error} />}
                </div>
                <div className="w-full flex justify-center mt-9">
                  <button
                    className="font-[Inter] w-3/2 text-primary font-semibold bg-main rounded-[6px] px-[20px] py-3 me-2 text-xs 2xl:text-xs"
                    onClick={handleConfirmClick}
                  >
                    Create Account
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CountrySelection;
