import { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ErrorMessage from "../../../components/ErrorMessage";

interface typeAssignProposedCPC {
  show: boolean;
  onClose: Function;
  assignProposedCPCAndClicks: Function;
  maxClicks: number;
}

const AssignProposedCPC: FC<typeAssignProposedCPC> = ({
  show,
  onClose,
  assignProposedCPCAndClicks,
  maxClicks,
}: typeAssignProposedCPC) => {
  const [proposedCPC, setProposedCPC] = useState(0);
  const [proposedClicks, setProposedClicks] = useState(0);
  const [error, setError] = useState("");
  const [clickError, setClickError] = useState("");

  const handleSubmit = () => {
    if (!!!proposedCPC || proposedCPC > 6) {
      setError("Proposed CPC must be greater than 0 and less than 6.");
      setClickError("");
    } else if (!!!proposedClicks || proposedClicks > maxClicks) {
      setError("");
      setClickError(
        `Proposed click must be greater than 0 and less than ${maxClicks}.`
      );
    } else {
      setError("");
      setClickError("");
      assignProposedCPCAndClicks(proposedCPC, proposedClicks);
      onClose();
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-black/[.8]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative bg-white rounded-[10px] text-left shadow-xl items-center flex flex-col w-[400px] px-[40px] pt-[30px] pb-[26px]`}
              >
                <button
                  onClick={() => onClose()}
                  className="absolute right-4 top-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M13.4444 13.4444L20.5556 20.5556M20.5556 13.4444L13.4444 20.5556M17 1C29.8 1 33 4.2 33 17C33 29.8 29.8 33 17 33C4.2 33 1 29.8 1 17C1 4.2 4.2 1 17 1Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="text-left w-full mt-3">
                  <div>
                    <p className="text-xs font-[Inter] font-medium -tracking-[.48px]">
                      Enter proposed CPC
                    </p>
                    <input
                      type="text"
                      value={proposedCPC}
                      onChange={(e: any) => setProposedCPC(e.target.value)}
                      className="mt-2 w-full text-xs font-[Inter] px-4 py-3 focus:ring-0 focus:border-black -tracking-[.48px] text-primary border-black border-[1px] rounded-[10px] bg-[#FBFBFB]"
                    />
                    <ErrorMessage message={error} />
                  </div>
                  <div className="mt-2">
                    <p className="text-xs font-[Inter] font-medium -tracking-[.48px]">
                      Enter proposed Clicks
                    </p>
                    <input
                      type="text"
                      value={proposedClicks}
                      onChange={(e: any) => setProposedClicks(e.target.value)}
                      className="mt-2 w-full text-xs font-[Inter] px-4 py-3 focus:ring-0 focus:border-black -tracking-[.48px] text-primary border-black border-[1px] rounded-[10px] bg-[#FBFBFB]"
                    />
                    <ErrorMessage message={clickError} />
                  </div>
                  <button
                    className="bg-black rounded-[10px] text-xs text-white font-semibold font-[Inter] py-3 w-full mt-8 disabled:bg-[#7f8183]"
                    onClick={handleSubmit}
                  >
                    Assign
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AssignProposedCPC;
