import { Avatar, Menu, MenuProps } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading";
import { getPlaceHolder } from "../../../utils/commonUtils";
import moment from "moment";
import { GetItem, MenuItem } from "../../../containers/shared/GetItem";
import AdminAPIInstance from "../../../api/adminApi";
import { capitalize } from "lodash";
import AssignCampaignToPublisher from "../models/AssignCampaignToPublisher";

const NewCampaignRequests = () => {
  const [campaign, setCampaigns] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAssignModel, setShowAssignModel] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const [searchStr, setSearchStr] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("Newest to Oldest");
  const ref = useRef<any>(null);

  const loadCampaigns = useCallback(async () => {
    setLoading(true);
    const { data } = await AdminAPIInstance.get("/getNewRequestCampaigns");
    setCampaigns(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const handleOpenChange = () => {
    setOpen(true);
  };

  const onClick: MenuProps["onClick"] = (e) => {
    setSort(e.key);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const items: MenuItem[] = [
    GetItem("Newest to Oldest", "Newest to Oldest"),
    GetItem("Oldest to Newest", "Oldest to Newest"),
  ];

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        hide();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAssignClick = (item: any) => {
    setSelectedCampaign(item);
    setShowAssignModel(true);
  };

  const handleClose = () => {
    setSelectedCampaign({});
    setShowAssignModel(false);
  };

  return (
    <div className="mt-3 h-full">
      <div className="top-35vh relative">{loading && <Loading />}</div>
      <div className="text-left relative pt-1.5">
        <div className="flex items-center w-full gap-5">
          <div className="flex w-[342px] border-[2px] rounded-[10px] border-main items-center px-4 py-2 bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              className="me-4"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M12.0016 1.07199C12.9542 1.62201 13.7832 2.36255 14.4368 3.24735C15.0903 4.13216 15.5544 5.14222 15.8 6.21444C16.0456 7.28666 16.0675 8.39801 15.8643 9.47908C15.6611 10.5601 15.2372 11.5877 14.619 12.4976L19.5637 17.4412C19.839 17.7125 19.9989 18.0795 20.0102 18.4659C20.0216 18.8522 19.8833 19.228 19.6244 19.5149C19.3655 19.8018 19.0058 19.9777 18.6203 20.006C18.2349 20.0342 17.8534 19.9126 17.5554 19.6665L17.4414 19.5635L12.4977 14.6188C11.3149 15.4222 9.93848 15.894 8.51156 15.9851C7.08464 16.0761 5.65938 15.7832 4.38408 15.1366C3.10878 14.4901 2.03003 13.5136 1.26007 12.3088C0.490105 11.104 0.0570647 9.71489 0.00600086 8.28598L0 8.00094L0.0050008 7.7159C0.0542013 6.33646 0.459431 4.99321 1.18131 3.8167C1.90318 2.64019 2.91715 1.67044 4.12465 1.00171C5.33216 0.332977 6.69213 -0.0119965 8.07239 0.00031853C9.45265 0.0126336 10.8063 0.381819 12.0016 1.07199Z"
                fill="#7F8182"
              />
              <circle cx="8.00586" cy="8.00488" r="6" fill="#F5F5F5" />
            </svg>
            <input
              className="me-2 font-[Inter] flex-1 border-0 text-sm text-primary focus:ring-0 p-0 focus:border-secondry2"
              placeholder="Type here to search by campaign name"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
            />
          </div>
          <div
            ref={ref}
            className="group inline-flex flex-col min-w-[100px] relative"
          >
            <button
              onMouseEnter={handleOpenChange}
              className={`font-[Inter] text-[14px] font-normal items-center justify-center text-primary justify-between flex px-4 py-2 gap-4 rounded-[10px] bg-white ring-2 ring-main shadow-md focus:ring-main`}
            >
              {sort}
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="1.75"
                  x2="18"
                  y2="1.75"
                  stroke="#505050"
                  strokeWidth="2.5"
                />
                <line
                  x1="2"
                  y1="5"
                  x2="16"
                  y2="5"
                  stroke="#505050"
                  strokeWidth="2"
                />
                <line x1="7" y1="11.5" x2="11" y2="11.5" stroke="#505050" />
                <line
                  x1="4"
                  y1="8.25"
                  x2="14"
                  y2="8.25"
                  stroke="#505050"
                  strokeWidth="1.5"
                />
              </svg>
            </button>
            {open && (
              <Menu
                selectedKeys={[sort]}
                onClick={onClick}
                items={items}
                className="w-[300px] absolute top-[calc(100%+5px)] !shadow-md rounded-[10px] text-left z-[9] !focus:ring-main"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-3 h-full">
        <div className={`rounded-[10px] grid grid-cols-3 gap-3`}>
          {(searchStr
            ? campaign.filter((x) =>
                x.name.toLowerCase().includes(searchStr.toLowerCase())
              )
            : campaign
          ).map((item, index) => (
            <div
              key={index}
              className="card rounded-[10px] bg-white shadow-md min-h-[183px] p-3 flex flex-col"
            >
              <div className="flex flex-row gap-2 shrink-0 justify-between">
                <div className="flex flex-col gap-3">
                  <div className="flex items-center">
                    <Avatar
                      src={item?.client_avatar}
                      className={`border border-solid border-secondry3 min-w-[66px] ${
                        item?.client_avatar ? "" : "bg-[#7f8182]"
                      }`}
                      size={66}
                    >
                      {!item?.client_avatar && getPlaceHolder(item.client_name)}
                    </Avatar>
                    <div className="ms-2">
                      <p className="text-primary text-[15px] font-medium font-[Inter] -tracking-[.36px]">
                        {item.name}
                      </p>
                      <p className="text-[10px] font-light font-[Inter] -tracking-[.36px]">
                        {item.client_name}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-end justify-between w-full mb-2">
                    <div className="w-auto">
                      <p className="text-primary font-[Inter] text-[12px] font-semibold">
                        Target Audience
                      </p>
                      <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1">
                        <button className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]">
                          {capitalize(item.demographic)}
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 items-end">
                  <p className="font-normal text-[10px] text-secondry1">
                    Submitted on:{" "}
                    {moment(Number(item.create_time)).format("MM/DD/yyyy")}
                  </p>
                  <div
                    className={`text-primary font-semibold px-4 py-[4px] font-[Inter] rounded-[10px] sm:w-[170px] bg-white border border-solid border-main shadow-md`}
                  >
                    <p className="text-[12px] text-[#172935] font-medium">
                      Budget
                    </p>
                    <p className="text-[16px] text-secondry1 font-semibold">
                      ${item?.price}
                    </p>
                  </div>
                  <div
                    className={`text-primary font-semibold px-4 py-[4px] font-[Inter] rounded-[10px] sm:w-[170px] bg-white border border-solid border-main shadow-md`}
                  >
                    <p className="text-[12px] text-[#172935] font-medium">
                      Presspool Budget
                    </p>
                    <p className="text-[16px] text-secondry1 font-semibold">
                      ${item?.presspool_budget}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1">
                <div className="flex items-end justify-between w-full mb-2">
                  <div className="w-auto">
                    <p className="text-primary font-[Inter] text-[12px] font-semibold">
                      Target Industrie(s)
                    </p>
                    <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                      {(item.audience || []).map(
                        (aud: string, index: number) => (
                          <button
                            key={index}
                            className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]"
                          >
                            {aud}
                          </button>
                        )
                      )}
                    </p>
                  </div>
                </div>
                {item.position && (
                  <div className="flex items-end justify-between w-full mb-2">
                    <div className="w-auto">
                      <p className="text-primary font-[Inter] text-[12px] font-semibold">
                        Target Demographic(s)
                      </p>
                      <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                        {(item.position || []).map(
                          (pos: string, index: number) => (
                            <button
                              key={index}
                              className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]"
                            >
                              {pos}
                            </button>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex items-end justify-between w-full mb-2">
                  <div className="w-auto">
                    <p className="text-primary font-[Inter] text-[12px] font-semibold">
                      Target Region(s)
                    </p>
                    <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                      {(item.region || []).map((reg: string, index: number) => (
                        <button
                          key={index}
                          className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]"
                        >
                          {reg}
                        </button>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center">
                <button
                  onClick={() => handleAssignClick(item)}
                  className="font-[Inter] w-3/2 text-[white] bg-black font-semibold rounded-[6px] px-[20px] py-2 me-2 text-xs 2xl:text-xs"
                >
                  Manage
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedCampaign?.id && (
        <AssignCampaignToPublisher
          show={showAssignModel}
          onClose={handleClose}
          item={selectedCampaign}
        />
      )}
    </div>
  );
};

export default NewCampaignRequests;
