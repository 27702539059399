import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { capitalize } from "lodash";
import { getPlaceHolder } from "../../../utils/commonUtils";
import { Avatar } from "antd";
import { ConversionGoal } from "../../../constants/constant";
import AdminAPIInstance from "../../../api/adminApi";
import AssignProposedCPC from "./AssignProposedCPC";
import Loading from "../../../components/Loading";
import { DeleteOutlined } from "@ant-design/icons";

interface typeAssignCampaignToPublisher {
  show: boolean;
  onClose: Function;
  item: any;
}

const AssignCampaignToPublisher: FC<typeAssignCampaignToPublisher> = ({
  show,
  onClose,
  item,
}) => {
  const [showCPCModel, setShowCPCModel] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [availablePublications, setAvailablePublications] = useState<any[]>([]);
  const [selectedPublications, setSelectedPublications] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const loadPublications = useCallback(async () => {
    setLoading(true);
    const { data } = await AdminAPIInstance.get(
      "/getAvailablePublicationsByCampaignId",
      { params: { campaignId: item.id } }
    );
    setAvailablePublications((data || []).filter((x: any) => !x.requeststatus));
    setSelectedPublications((data || []).filter((x: any) => !!x.requeststatus));
    setLoading(false);
  }, [item.id]);

  useEffect(() => {
    loadPublications();
  }, [loadPublications]);

  const selectPublication = (item: any) => {
    setSelectedItem(item);
    setShowCPCModel(true);
  };

  const deselectPublication = async (item: any) => {
    if (item.requestid) {
      setLoading(true);
      await AdminAPIInstance.delete(`/unassignCampaignById/${item.requestid}`);
      loadPublications();
    } else {
      setSelectedPublications(
        selectedPublications.filter(
          (x) => x.publication_id !== item.publication_id
        )
      );
      setAvailablePublications([...availablePublications, item]);
    }
  };

  const assignProposedCPCAndClicks = (cpc: any, clicks: any) => {
    setAvailablePublications(
      availablePublications.filter(
        (x) => x.publication_id !== selectedItem.publication_id
      )
    );
    setSelectedPublications([
      ...selectedPublications,
      { ...selectedItem, proposed_cpc: cpc, proposed_clicks: clicks },
    ]);
  };

  const sendCampaigns = async () => {
    const items = selectedPublications
      .filter((x) => !x.requestid)
      .map((item) => ({
        publisherId: item.publisher_id,
        cpc: item.proposed_cpc,
        clicks: item.proposed_clicks,
      }));
    if (items.length > 0) {
      setLoading(true);
      await AdminAPIInstance.post("/sendCampaignRequestToPublications", {
        campaignId: item.id,
        data: items,
      });
      loadPublications();
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-black/[.8]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <Dialog.Panel
                  className={`relative bg-white rounded-[10px] text-left flex flex-col shadow-xl border-[1px] border-black px-[12px] pt-[45px] pb-[12px] min-w-full min-h-full h-full bg-white`}
                >
                  {loading && <Loading />}
                  <button
                    onClick={() => onClose()}
                    className="absolute right-4 top-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                    >
                      <path
                        d="M13.4444 13.4444L20.5556 20.5556M20.5556 13.4444L13.4444 20.5556M17 1C29.8 1 33 4.2 33 17C33 29.8 29.8 33 17 33C4.2 33 1 29.8 1 17C1 4.2 4.2 1 17 1Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <div className="grid grid-cols-3 w-full h-full gap-1 mt-2">
                    <div className="min-h-full p-2 border-r-[1px] border-secondry3 ">
                      <p className="text-primary text-[25px] font-[Inter] font-semibold -tracking-[.36px]">
                        Campaigns Details
                      </p>
                      <div className="max-h-96p overflow-y-scroll pr-[10px]">
                        <div className="flex flex-row gap-2 shrink-0 justify-between flex-1">
                          <div className="flex flex-col gap-3">
                            <div className="flex items-center">
                              <Avatar
                                src={item?.client_avatar}
                                className={`border border-solid border-secondry3 ${
                                  item?.client_avatar ? "" : "bg-[#7f8182]"
                                }`}
                                size={66}
                              >
                                {!item?.client_avatar &&
                                  getPlaceHolder(item.client_name)}
                              </Avatar>
                              <div className="ms-2">
                                <p className="text-primary text-[15px] font-medium font-[Inter] -tracking-[.36px]">
                                  {item.name}
                                </p>
                                <p className="text-[10px] font-light font-[Inter] -tracking-[.36px]">
                                  {item.client_name}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col ">
                              <div className="flex items-end justify-between w-full mb-2">
                                <div className="w-auto">
                                  <p className="text-primary font-[Inter] text-[12px] font-semibold">
                                    Target Audience
                                  </p>
                                  <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1">
                                    <button className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]">
                                      {capitalize(item.demographic)}
                                    </button>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2 items-end">
                            <p className="font-normal text-[10px] text-secondry1">
                              Submitted on:{" "}
                              {moment(Number(item.create_time)).format(
                                "MM/DD/yyyy"
                              )}
                            </p>
                            <div
                              className={`text-primary font-semibold px-4 py-[2px] font-[Inter] rounded-[10px] sm:w-[170px] bg-white border border-solid border-main shadow-md`}
                            >
                              <p className="text-[12px] text-[#172935] font-medium">
                                Budget
                              </p>
                              <p className="text-[16px] text-secondry1 font-semibold">
                                ${item?.price}
                              </p>
                            </div>
                            <div
                              className={`text-primary font-semibold px-4 py-[2px] font-[Inter] rounded-[10px] sm:w-[170px] bg-white border border-solid border-main shadow-md`}
                            >
                              <p className="text-[12px] text-[#172935] font-medium">
                                Presspool Budget
                              </p>
                              <p className="text-[16px] text-secondry1 font-semibold">
                                ${item?.presspool_budget}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex items-end justify-between w-full mb-2">
                            <div className="w-auto">
                              <p className="text-primary font-[Inter] text-[12px] font-semibold">
                                Target Industrie(s)
                              </p>
                              <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                                {(item.audience || []).map(
                                  (aud: string, index: number) => (
                                    <button
                                      key={index}
                                      className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]"
                                    >
                                      {aud}
                                    </button>
                                  )
                                )}
                              </p>
                            </div>
                          </div>
                          {item.position && (
                            <div className="flex items-end justify-between w-full mb-2">
                              <div className="w-auto">
                                <p className="text-primary font-[Inter] text-[12px] font-semibold">
                                  Target Demographic(s)
                                </p>
                                <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                                  {(item.position || []).map(
                                    (pos: string, index: number) => (
                                      <button
                                        key={index}
                                        className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]"
                                      >
                                        {pos}
                                      </button>
                                    )
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="flex items-end justify-between w-full mb-2">
                            <div className="w-auto">
                              <p className="text-primary font-[Inter] text-[12px] font-semibold">
                                Target Region(s)
                              </p>
                              <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                                {(item.region || []).map(
                                  (reg: string, index: number) => (
                                    <button
                                      key={index}
                                      className="bg-black px-2 py-1 rounded text-white font-medium text-[12px] font-[Inter]"
                                    >
                                      {reg}
                                    </button>
                                  )
                                )}
                              </p>
                            </div>
                          </div>
                          <p className="text-primary font-[Inter] text-[12px] font-semibold">
                            Headline
                          </p>
                          <h2 className="font-[Inter] text-primary font-light text-[12px] -tracking-[.42px] mb-2">
                            {item?.headline}
                          </h2>
                          <p className="text-primary font-[Inter] text-[12px] font-semibold">
                            Body
                          </p>
                          <h2 className="font-[Inter] text-primary font-light text-[12px] -tracking-[.42px] mb-2">
                            {item?.body}
                          </h2>
                          <p className="text-primary font-[Inter] text-[12px] font-semibold">
                            CTA Text
                          </p>
                          <h2 className="font-[Inter] text-primary font-light text-[12px] -tracking-[.42px] mb-2">
                            {item?.cta}
                          </h2>
                          <p className="text-primary font-[Inter] text-[12px] font-semibold flex items-center">
                            Landing Page Preview
                          </p>
                          <h2 className="font-[Inter] text-[#6C63FF] font-light text-[12px] -tracking-[.42px] mb-2">
                            {item?.url}
                          </h2>
                          <p className="text-primary font-[Inter] text-[12px] font-semibold">
                            Conversion Goal
                          </p>
                          <h2 className="font-[Inter] text-primary font-light text-[12px] -tracking-[.42px] mb-2">
                            {item?.conversion
                              ? ConversionGoal[item?.conversion as never]
                              : ""}
                          </h2>
                          <p className="text-primary font-[Inter] text-[12px] font-semibold">
                            Hero Image
                          </p>
                          <div className="font-[Inter] text-primary font-light text-[12px] -tracking-[.42px] mb-2">
                            <img
                              src={item?.image}
                              alt="sample logo"
                              className="h-[59px] w-[82px] object-cover rounded-[10px]"
                            />
                          </div>
                          {item?.additional_files ? (
                            <>
                              <p className="text-primary font-[Inter] text-[12px] font-semibold">
                                Additional Assets
                              </p>
                              <div className="font-[Inter] text-primary font-medium text-[12px] -tracking-[.42px] mb-2 flex gap-[10px]">
                                {item.additional_files
                                  ?.split(",")
                                  .map((url: string) => (
                                    <img
                                      src={url}
                                      alt="sample logo"
                                      className="h-[59px] w-[82px] object-cover rounded-[10px]"
                                    />
                                  ))}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="min-h-full p-2 border-r-[1px] border-secondry3 overflow-hidden">
                      <p className="text-primary text-[25px] font-[Inter] font-semibold -tracking-[.36px]">
                        Available Publications
                      </p>
                      <div className="flex w-full border-[2px] rounded-[10px] border-main items-center px-4 py-2 bg-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          className="me-4"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M12.0016 1.07199C12.9542 1.62201 13.7832 2.36255 14.4368 3.24735C15.0903 4.13216 15.5544 5.14222 15.8 6.21444C16.0456 7.28666 16.0675 8.39801 15.8643 9.47908C15.6611 10.5601 15.2372 11.5877 14.619 12.4976L19.5637 17.4412C19.839 17.7125 19.9989 18.0795 20.0102 18.4659C20.0216 18.8522 19.8833 19.228 19.6244 19.5149C19.3655 19.8018 19.0058 19.9777 18.6203 20.006C18.2349 20.0342 17.8534 19.9126 17.5554 19.6665L17.4414 19.5635L12.4977 14.6188C11.3149 15.4222 9.93848 15.894 8.51156 15.9851C7.08464 16.0761 5.65938 15.7832 4.38408 15.1366C3.10878 14.4901 2.03003 13.5136 1.26007 12.3088C0.490105 11.104 0.0570647 9.71489 0.00600086 8.28598L0 8.00094L0.0050008 7.7159C0.0542013 6.33646 0.459431 4.99321 1.18131 3.8167C1.90318 2.64019 2.91715 1.67044 4.12465 1.00171C5.33216 0.332977 6.69213 -0.0119965 8.07239 0.00031853C9.45265 0.0126336 10.8063 0.381819 12.0016 1.07199Z"
                            fill="#7F8182"
                          />
                          <circle
                            cx="8.00586"
                            cy="8.00488"
                            r="6"
                            fill="#F5F5F5"
                          />
                        </svg>
                        <input
                          className="me-2 font-[Inter] flex-1 border-0 text-sm text-primary focus:ring-0 p-0 focus:border-secondry2"
                          placeholder="Type here to search by publication name"
                          value={searchStr}
                          onChange={(e) => setSearchStr(e.target.value)}
                        />
                      </div>
                      <div className="max-h-87p overflow-y-scroll bg-[#E3E3E3] rounded-[10px] p-3 mt-3 flex flex-col gap-3">
                        {availablePublications
                          .filter((x) =>
                            x.newsletter
                              .toLowerCase()
                              .includes(searchStr.toLowerCase())
                          )
                          .map((publication: any, index) => (
                            <div
                              key={index}
                              className={`hover:bg-[#F5F5F5] card rounded-[10px] bg-white min-h-auto p-3 flex flex-col cursor-pointer ${
                                publication.requeststatus === "ACCEPTED" ||
                                publication.requeststatus === "PENDING"
                                  ? "!shadow-green"
                                  : publication.requeststatus === "REJECTED"
                                  ? "!shadow-red"
                                  : ""
                              }`}
                              onClick={() => selectPublication(publication)}
                            >
                              <div className="flex shrink-0 justify-between">
                                <div className="flex items-center">
                                  <Avatar
                                    src={publication?.team_avatar}
                                    className={`${
                                      publication?.team_avatar
                                        ? ""
                                        : "bg-[#7f8182]"
                                    }`}
                                    size={66}
                                  >
                                    {!publication?.team_avatar &&
                                      getPlaceHolder(publication.newsletter)}
                                  </Avatar>
                                  <div className="ms-2">
                                    <p className="text-primary text-[15px] font-[Inter] -tracking-[.36px]">
                                      {publication?.newsletter}
                                    </p>
                                    <p className="text-secondry1 text-[10px] font-light font-[Inter] -tracking-[.36px]">
                                      {publication?.website_url}
                                    </p>
                                    <p className="text-secondry1 text-[10px] font-light font-[Inter] -tracking-[.36px]">
                                      {publication?.email}
                                    </p>
                                  </div>
                                </div>
                                {publication.requeststatus === "ACCEPTED" ||
                                publication.requeststatus === "PENDING" ? (
                                  <p className="font-normal text-[10px] text-secondry1">
                                    Applied:{" "}
                                    {moment(
                                      Number(publication.requestcreatetime)
                                    ).format("MM/DD/yyyy")}
                                  </p>
                                ) : publication.requeststatus === "REJECTED" ? (
                                  <p className="font-normal text-[10px] text-secondry1">
                                    Rejected:{" "}
                                    {moment(
                                      Number(publication.requestrejecteddate)
                                    ).format("MM/DD/yyyy")}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="grid grid-cols-3 gap-3 mt-4">
                                <div className="min-h-[30px] border-[1px] border-main rounded-[10px] py-1 px-2">
                                  <p className="text-[#172935] text-[12px] font-[Inter] font-medium -tracking-[.36px]">
                                    Subscribers
                                  </p>
                                  <p className="text-primary text-[14px] font-[Inter] font-semibold -tracking-[.36px]">
                                    {publication?.total_subscribers}
                                  </p>
                                </div>
                                <div className="min-h-[30px] border-[1px] border-main rounded-[10px] py-1 px-2">
                                  <p className="text-[#172935] text-[12px] font-[Inter] font-medium -tracking-[.36px]">
                                    CPC
                                  </p>
                                  <p className="text-primary text-[14px] font-[Inter] font-semibold -tracking-[.36px]">
                                    ${publication?.cpc ?? 0}
                                  </p>
                                </div>
                                <div className="min-h-[30px] border-[1px] border-main rounded-[10px] py-1 px-2">
                                  <p className="text-[#172935] text-[12px] font-[Inter] font-medium -tracking-[.36px]">
                                    72hr Clicks
                                  </p>
                                  <p className="text-primary text-[14px] font-[Inter] font-semibold -tracking-[.36px]">
                                    {publication?.average_unique_click}
                                  </p>
                                </div>
                              </div>
                              <div className="w-full flex flex-col items-start justify-center">
                                <div className="flex items-end justify-between w-full mb-2 mt-3">
                                  <div className="w-auto">
                                    <p className="text-[#A3A3A3] font-[Inter] text-[10px] font-medium">
                                      Target Audience
                                    </p>
                                    <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1">
                                      <button className="bg-black px-2 py-1 rounded text-white font-medium font-[Inter] text-[8px]">
                                        {capitalize(publication.audience)}
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                <div className="flex items-end justify-between w-full mb-2">
                                  <div className="w-auto">
                                    <p className="text-[#A3A3A3] font-[Inter] text-[10px] font-medium">
                                      Target Industrie(s)
                                    </p>
                                    <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                                      {(publication.industry || []).map(
                                        (aud: string, index: number) => (
                                          <button
                                            key={index}
                                            className="bg-black px-2 py-1 rounded text-white font-medium font-[Inter] text-[8px]"
                                          >
                                            {aud}
                                          </button>
                                        )
                                      )}
                                    </p>
                                  </div>
                                </div>
                                {publication.position && (
                                  <div className="flex items-end justify-between w-full mb-2">
                                    <div className="w-auto">
                                      <p className="text-[#A3A3A3] font-[Inter] text-[10px] font-medium">
                                        Target Demographic(s)
                                      </p>
                                      <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                                        {(publication.position || []).map(
                                          (pos: string, index: number) => (
                                            <button
                                              key={index}
                                              className="bg-black px-2 py-1 rounded text-white font-semibold font-[Inter] text-[8px]"
                                            >
                                              {pos}
                                            </button>
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <div className="flex items-end justify-between w-full">
                                  <div className="w-auto">
                                    <p className="text-[#A3A3A3] font-[Inter] text-[10px] font-medium">
                                      Target Region(s)
                                    </p>
                                    <p className="text-primary font-[Inter] font-medium -tracking-[.47px] flex gap-1 flex-wrap">
                                      {(publication.geography || []).map(
                                        (reg: string, index: number) => (
                                          <button
                                            key={index}
                                            className="bg-black px-2 py-1 rounded text-white font-semibold font-[Inter] text-[8px]"
                                          >
                                            {reg}
                                          </button>
                                        )
                                      )}
                                    </p>
                                  </div>
                                </div>
                                {publication.state === "REJECTED" && (
                                  <div className="flex items-end justify-between w-full mb-2">
                                    <div className="w-full">
                                      <p className="text-[#A3A3A3] font-[Inter] text-[10px] font-medium">
                                        Rejection Note
                                      </p>
                                      <div className="font-[Inter] text-primary font-medium text-[10px] -tracking-[.42px] mb-2 min-h-[78px] w-full border-[1px] border-[#7f8182] rounded-[10px] p-1">
                                        {publication.rejected_notes}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="min-h-full p-2 flex flex-col gap-4">
                      <p className="text-primary text-[25px] font-[Inter] font-semibold -tracking-[.36px]">
                        Review and Send
                      </p>
                      <div className="max-h-90p overflow-y-scroll bg-[#E3E3E3] rounded-[10px] p-3 mt-3 flex flex-col gap-3">
                        {selectedPublications.map((publication: any, index) => (
                          <div
                            key={index}
                            className={`card rounded-[10px] bg-white min-h-auto p-3 flex flex-col ${
                              publication.requeststatus === "ACCEPTED" ||
                              publication.requeststatus === "RUNNING"
                                ? "!shadow-green"
                                : publication.requeststatus === "REJECTED"
                                ? "!shadow-red"
                                : publication.requeststatus === "PENDING"
                                ? "!shadow-yellow"
                                : ""
                            }`}
                          >
                            <div className="flex shrink-0 justify-between">
                              <div className="flex items-center">
                                <Avatar
                                  src={publication?.team_avatar}
                                  className={`${
                                    publication?.team_avatar
                                      ? ""
                                      : "bg-[#7f8182]"
                                  }`}
                                  size={66}
                                >
                                  {!publication?.team_avatar &&
                                    getPlaceHolder(publication.newsletter)}
                                </Avatar>
                                <div className="ms-2">
                                  <p className="text-primary text-[15px] font-[Inter] -tracking-[.36px]">
                                    {publication?.newsletter}
                                  </p>
                                  <p className="text-secondry1 text-[10px] font-light font-[Inter] -tracking-[.36px]">
                                    {publication?.website_url}
                                  </p>
                                  <p className="text-secondry1 text-[10px] font-light font-[Inter] -tracking-[.36px]">
                                    {publication?.email}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-1 justify-end items-start gap-2">
                                {publication.requeststatus === "PENDING" ? (
                                  <p className="font-normal text-[10px] text-secondry1">
                                    Applied:{" "}
                                    {moment(
                                      Number(publication.requestcreatetime)
                                    ).format("MM/DD/yyyy")}
                                  </p>
                                ) : publication.requeststatus === "REJECTED" ? (
                                  <p className="font-normal text-[10px] text-secondry1">
                                    Rejected:{" "}
                                    {moment(
                                      Number(publication.requestrejecteddate)
                                    ).format("MM/DD/yyyy")}
                                  </p>
                                ) : publication.requeststatus === "ACCEPTED" ||
                                  publication.requeststatus === "RUNNING" ? (
                                  <p className="font-normal text-[10px] text-secondry1">
                                    Scheduled:{" "}
                                    {moment(
                                      Number(publication.requestscheduleddate)
                                    ).format("MM/DD/yyyy")}
                                  </p>
                                ) : (
                                  <></>
                                )}
                                {(!publication.requeststatus ||
                                  publication.requeststatus === "PENDING") && (
                                  <DeleteOutlined
                                    style={{ color: "red" }}
                                    onClick={() =>
                                      deselectPublication(publication)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 mt-4">
                              <div className="min-h-[30px] border-[1px] border-main rounded-[10px] py-1 px-2">
                                <p className="text-[#172935] text-[12px] font-[Inter] font-medium -tracking-[.36px]">
                                  Subscribers
                                </p>
                                <p className="text-primary text-[14px] font-[Inter] font-semibold -tracking-[.36px]">
                                  {publication?.total_subscribers}
                                </p>
                              </div>
                              <div className="min-h-[30px] border-[1px] border-main rounded-[10px] py-1 px-2">
                                <p className="text-[#172935] text-[12px] font-[Inter] font-medium -tracking-[.36px]">
                                  CPC
                                </p>
                                <p className="text-primary text-[14px] font-[Inter] font-semibold -tracking-[.36px]">
                                  $
                                  {publication.proposed_cpc ??
                                    publication?.cpc ??
                                    0}
                                </p>
                              </div>
                              <div className="min-h-[30px] border-[1px] border-main rounded-[10px] py-1 px-2">
                                <p className="text-[#172935] text-[12px] font-[Inter] font-medium -tracking-[.36px]">
                                  72hr Clicks
                                </p>
                                <p className="text-primary text-[14px] font-[Inter] font-semibold -tracking-[.36px]">
                                  {publication.proposed_clicks ??
                                    publication?.average_unique_click ??
                                    0}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="w-full flex justify-center">
                        <button
                          onClick={sendCampaigns}
                          className={`text-xs font-[Inter] shadow-md items-center font-semibold text-center px-2 py-3 bg-main rounded-[10px] text-primary ring-0 min-w-[173px]`}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
          {showCPCModel && (
            <AssignProposedCPC
              show={showCPCModel}
              onClose={() => setShowCPCModel(false)}
              assignProposedCPCAndClicks={assignProposedCPCAndClicks}
              maxClicks={selectedItem?.average_unique_click}
            />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AssignCampaignToPublisher;
